<template>
  <div class="page">
    <section v-if="detail">
      <div class="content">
        <div class="contentOne">
          <i @click="decrease" class="iconfont fanhui2" v-if="index">上一题</i>
          <Countdown
            :time="detail.duration * 60"
            format="hh:mm:ss"
            @on-end="finish"
          >
            <template slot-scope="{ time }" class="countdown">{{
              time
            }}</template>
          </Countdown>
        </div>
        <el-progress text-inside :percentage="percentage" color="customColors">
        </el-progress>
      </div>
      <div class="topic">
        <el-carousel
          indicator-position="none"
          :loop="false"
          :autoplay="false"
          :initial-index="index"
          @change="changeIndex"
          arrow="never"
          ref="swiper"
        >
          <el-carousel-item
            v-for="(item, index) in detail.data"
            :key="item.id"
            name="index"
          >
            <div class="title">{{ index + 1 }}.{{ item.caption }}</div>

            <!-- 单选题 -->
            <div class="radioBox" v-if="item.type == '单选题'">
              <el-radio-group v-model="item.check" size="small ">
                <el-radio
                  class="radioBox-li"
                  :label="it.id"
                  v-for="(it, i) in item.answer"
                  :key="i"
                >
                  {{ it.value }}
                </el-radio>
              </el-radio-group>
            </div>

            <!-- 多选题 -->
            <div class="radioBox" v-if="item.type == '多选题'">
              <el-checkbox-group v-model="item.check">
                <el-checkbox
                  class="radioBox-li"
                  :label="it.id"
                  v-for="(it, i) in item.answer"
                  :key="i"
                  >{{ it.value }}</el-checkbox
                >
              </el-checkbox-group>
            </div>

            <!-- 量表题 -->
            <div class="scale" v-if="item.type == '量表题'">
              <div class="top">
                <span>非常不满意</span>
                <span>非常满意</span>
              </div>
              <div class="bottom">
                <span
                  @click="item.check = i + 1"
                  :class="{ active: item.check == i + 1 }"
                  v-for="(it, i) in item.answer[0].value * 1"
                  :key="i"
                  >{{ i + 1 }}</span
                >
              </div>
            </div>

            <!-- 多行文本题 -->
            <div class="textarea" v-if="item.type == '多行文本题'">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入"
                v-model="item.check"
                autocomplete="off"
              >
              </el-input>
            </div>

            <!-- 多行文本题 -->
            <div class="textarea" v-if="item.type == '文本描述'">
              <el-input
                autocomplete="off"
                type="textarea"
                :rows="3"
                placeholder="请输入"
                v-model="item.check"
              >
              </el-input>
            </div>

            <!-- 单行文本题 -->
            <div class="textarea" v-if="item.type == '单行文本题'">
              <el-input
                autocomplete="off"
                placeholder="请输入"
                v-model="item.check"
              >
              </el-input>
            </div>

            <!-- 下拉题 -->
            <div class="textarea" v-if="item.type == '下拉题'">
              <el-select v-model="item.check" placeholder="请选择">
                <el-option
                  v-for="(it, i) in item.answer"
                  :key="i"
                  :label="it.value"
                  :value="it.id"
                >
                </el-option>
              </el-select>
            </div>

            <!-- 排序题 -->
            <div class="textarea" v-if="item.type == '排序题'">
              <div class="sort">
                <div class="top">拖动右边把手排序</div>
                <div class="bottom">
                  <draggable
                    v-model="item.answer"
                    chosen-class="chosen"
                    force-fallback="true"
                    group="people"
                    handle=".el-icon-sort"
                    animation="1000"
                    @end="onEnd"
                  >
                    <transition-group>
                      <div
                        class="sort-item"
                        v-for="element in item.answer"
                        :key="element.id"
                      >
                        {{ element.value }}
                        <i class="el-icon-sort"></i>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>

            <!-- 附件题 -->
            <div class="textarea" v-if="item.type == '附件题'">
              <div class="upload">
                <el-upload
                  class="upload-demo"
                  :limit="1"
                  ref="upload"
                  :headers="{ token: token }"
                  :action="$http.baseURL + '/dkt/other/upload'"
                  name="files"
                  :on-success="succseeFile"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  list-type="picture"
                >
                  <el-button size="small" type="primary" v-if="isUpload"
                    >点击上传</el-button
                  >
                </el-upload>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="switch">
        <span
          @click="increase"
          class="under"
          v-show="index != detail.data.length - 1"
          >下一题</span
        >

        <el-button type="text">
          <span class="under2" @click="commit">交卷</span>
        </el-button>

        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="560px"
          :before-close="handleClose"
        >
          <span
            >试卷共{{ detail.data.length }}道题，还有{{
              detail.data.length - num
            }}道题没答您确定要交卷吗？</span
          >
          <span slot="footer" class="dialog-footer">
            <div class="popUps">
              <span @click="dialogVisible = !dialogVisible" class="cancel"
                >取消</span
              >
              <span @click="comfirm" class="cancel">确认</span>
            </div>
          </span>
        </el-dialog>
        <!-- <span
          @click="
            $router.push({ path: '/research-questionnaire-endofQuestionnaire' })
          "
          class="under"
          >完成</span> -->
        <el-dialog
          title="提示"
          :visible.sync="isTime"
          width="560px"
          :close-on-click-modal="false"
          :before-close="handleClose"
        >
          <span>考试时间已结束，请提交试卷！</span>
          <span slot="footer" class="dialog-footer">
            <div class="popUps">
              <span @click="comfirm" class="cancel">确认</span>
            </div>
          </span>
        </el-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Countdown from "@choujiaojiao/vue2-countdown";

export default {
  components: {
    draggable,
    Countdown,
  },
  data() {
    return {
      detail: "",
      isTime: false,
      index: 0,
      dialogVisible: false,
      percentage: 0,
      num: 0,
      isUpload: true,
      token: localStorage.getItem("token") || "",
      fileList: [],
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async succseeFile(response, file, fileList) {
      if (response.code == 200) {
        this.isUpload = false;
        this.detail.data[this.index].check = response.data;
      }
    },
    handleRemove(file, fileList) {
      this.detail.data[this.index].check = "";
      this.isUpload = true;
    },
    // 计时结束
    finish() {
      this.isTime = true;
    },
    // 拖动结束
    onEnd(e) {
      this.drag = false;
      let arr = [];
      for (let i in this.detail.data[this.index].answer) {
        let item = this.detail.data[this.index].answer[i];
        arr.push(item.id);
      }
      this.detail.data[this.index].check = arr;
    },
    // 轮播图滚动
    changeIndex(e) {
      this.index = e;
    },
    // 详情
    async getDetail() {
      this.xin.isLoadding(true);
      let res = await this.$researchApi.questionnaireDetail({
        questionnaire_id: this.$route.query.id,
      });
      if (res.code == 200) {
        let data = res.data || [];
        for (let i in data.data) {
          data.data[i].check = "";
          if (data.data[i].type == "多选题") {
            data.data[i].check = [];
          }
        }
        this.detail = data;
      }
      this.xin.isLoadding();
    },
    handleClose(done) {
      done();
    },
    // 下一题
    increase() {
      this.$refs.swiper.setActiveItem(this.index + 1);
      this.getPercentage();
    },
    // 上一题
    decrease() {
      this.$refs.swiper.setActiveItem(this.index - 1);
      this.getPercentage();
    },
    // 获取进度
    getPercentage() {
      let num = 0;
      for (let i in this.detail.data) {
        if (
          this.detail.data[i].type == "多选题" &&
          this.detail.data[i].check.length
        ) {
          num += 1;
        } else if (
          this.detail.data[i].type != "多选题" &&
          this.detail.data[i].check
        ) {
          num += 1;
        }
      }
      this.num = num;
      this.percentage = parseInt((num / this.detail.data.length) * 100);
    },
    // 交卷
    commit() {
      this.getPercentage();
      if (this.percentage != 100) {
        this.dialogVisible = true;
      } else {
        this.comfirm();
      }
    },
    // 确认交卷
    async comfirm() {
      let data = {
        questionnaire_id: this.$route.query.id,
        user_source: 3,
        list: [],
      };
      for (let i in this.detail.data) {
        let answer = this.detail.data[i].check;
        if (
          this.detail.data[i].type == "单行文本题" ||
          this.detail.data[i].type == "多行文本题" ||
          this.detail.data[i].type == "文本描述" ||
          this.detail.data[i].type == "量表题"
        ) {
          data.list.push({
            id: this.detail.data[i].id,
            value: answer,
          });
        } else {
          if (!(answer instanceof Array)) {
            answer = [answer];
          }
          data.list.push({
            id: this.detail.data[i].id,
            answer,
          });
        }
      }
      this.dialogVisible = false;
      let res = await this.$researchApi.addQuestions(data);
      if (res.code == 200) {
        // 重定向
        this.$router.replace(
          "/research-questionnaire-endofQuestionnaire?price=" +
            this.detail.cash_gift
        );
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/css/themeColor.scss";

.upload {
  width: 300px;
}
.radioBox {
  margin-top: 20px;
  ::v-deep {
    .el-radio__inner {
      width: 18px;
      height: 18px;
    }
  }
  .radioBox-li {
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 21px;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.scale {
  margin-top: 20px;
  .top {
    height: 30rpx;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      padding: 4px 14px;
      background-color: #f2f2f2;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
  .bottom {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    span {
      cursor: pointer;
      width: 90px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d9d9d9;
      border-radius: 5px;
      font-size: 30px;
      font-weight: 400;
      color: #333333;
    }
    .active {
      background-color: $--themeColor;
      color: #ffffff;
    }
  }
}
.textarea {
  margin-top: 20px;
}
.sort {
  margin-top: 30px;
  .top {
    font-size: 15px;
    font-weight: 700;
    color: #8f8f8f;
    height: 21px;
    line-height: 21px;
  }
  .bottom {
    width: 100%;
    margin-top: 10px;
    .sort-item {
      cursor: pointer;
      width: 100%;
      height: 50px;
      display: flex;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .el-icon-sort {
        font-size: 20px;
        margin-left: 20px;
        color: $--themeColor;
      }
    }
  }
}

::v-deep {
  .el-dialog {
    padding: 50px 80px;
    box-sizing: border-box;
    .el-dialog__body {
      color: #333333;
      font-size: 24px;
      text-align: center;
      line-height: 40px;
    }
    .el-dialog__footer {
      text-align: center;
      margin-top: 50px;
      .popUps {
        display: flex;
        flex-direction: row;
        justify-content: center;
        > .cancel {
          cursor: pointer;
          &:first-child {
            margin-right: 30px;
          }
          &:last-child {
            background: $--themeColor;
            border: 0;
            color: #ffffff;
          }
          width: 170px;
          height: 50px;
          background: #ffffff;
          border: 1px solid $--themeColor;
          border-radius: 26px;
          line-height: 50px;
          color: $--themeColor;
        }
      }
    }
  }
  .el-progress-bar__outer {
    overflow: initial;
  }
  .el-progress-bar__innerText {
    color: #333;
    margin: 20px 5px;
  }
  .progressbar {
    > .el-progress__text {
      margin-top: 28px;
    }
  }
  .el-progress-bar {
    margin-top: 28px;
    > .el-progress-bar__outer {
      height: 15px !important;
      > .el-progress-bar__inner {
        background: $--themeColor !important;
      }
    }
  }
  .el-button-group {
    margin: 0 auto !important;
  }
}
.page {
  > section {
    > .content {
      display: flex;
      flex-direction: column;
      > .contentOne {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 30px;
        height: 24px;
        line-height: 24px;
        width: 350px;
        position: relative;
        > .countdown {
          font-size: 24px;
          color: #ff8307;
        }
        > .fanhui2 {
          position: absolute;
          left: 0;
          top: 0;
          cursor: pointer;
          font-size: 16px;
          color: $--themeColor;
          font-weight: 600;
        }
      }
    }
    > .topic {
      display: flex;
      flex-direction: column;
      margin-top: 57px;
      font-size: 18px;
      color: #333333;
      > .title {
        margin-bottom: 20px;
      }
      > .group {
        &:last-child {
          margin-bottom: 0;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        color: #333333;
        margin-bottom: 32px;
        > img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
      }
    }
    .switch {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 67px;
      .under {
        cursor: pointer;
        width: 384px;
        height: 46px;
        background: $--themeColor;
        border-radius: 23px;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 46px;
        margin-bottom: 20px;
      }
      .under2 {
        display: block;
        cursor: pointer;
        width: 384px;
        height: 46px;
        border: 1px solid $--themeColor;
        border-radius: 23px;
        font-size: 16px;
        color: $--themeColor;
        text-align: center;
        line-height: 46px;
      }
    }
  }
}
</style>